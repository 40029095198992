import React from "react"
import Layout from "../components/Layout"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"

const Vision = props => {
  const data = useStaticQuery(graphql`
    {
      file(relativePath: { eq: "wpf_vision_header.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  return (
    <Layout
      title=""
      secondTitle=""
      fluid={data.file.childImageSharp.fluid}
      seoTitle="WirtschaftsPlattForm Schwechat"
      seoDescription="Die WirtschaftsPlattForm Schwechat wurde mit dem Ziel gegründet, den Standort Schwechat als Einkaufsstadt für den Konsumenten attraktiver zu gestalten und dadurch die in Schwechat wirksame Kaufkraft zu erhöhen. Die WirtschaftsPlattForm Schwechat besteht als Verein und arbeitet nicht gewinnorientiert."
    heroSize="vision"    >
      <section className="section vorstellung">
        <div className="container">
          <article className="message is-large is-primary">
            <div className="message-header is-size-3 is-centered has-text-centered">
              <h4 className="title is-3 has-text-white">Unsere Vision</h4>
            </div>
            <div className="content message-body is-size-5 has-text-dark">
              <p>
                Die WirtschaftsPlattForm Schwechat möchte unsere Stadt in erster
                Linie für KonsumentInnen attraktiv gestalten.
              </p>
              <p>
                „Regional ist genial " gewinnt im Lichte von gestiegenen
                Ansprüchen an Regionalität, Umweltschutz und Steuergerechtigkeit
                neue Kraft.
              </p>
              <p>
                Ziel des gemeinnützigen Vereins ist es, eine ansprechende
                Nahversorgung über die Grundbedürfnisse hinaus zu ermöglichen,
                damit für alle Beteiligten – KonsumentInnen, UnternehmerInnen
                und Stadtgemeinde – ein vorteilhaftes Miteinander entsteht!
              </p>
            </div>
          </article>
          <div className="has-text-centered pt-3">
            <Link to="/" className="button is-link is-medium">
              Zur Startseite
            </Link>
          </div>
        </div>
      </section>{" "}
    </Layout>
  )
}
export default Vision
